"use strict";
import { defineStore } from "pinia";
import {
  MarketType,
  UiDerivativeTransformer,
  zeroDerivativeMarketSummary
} from "@injectivelabs/sdk-ui-ts";
import {
  DerivativeOrderState
} from "@injectivelabs/sdk-ts";
import {
  cancelOrder,
  batchCancelOrder,
  submitLimitOrder,
  submitMarketOrder,
  submitStopLimitOrder,
  submitStopMarketOrder
} from "@/store/derivative/message";
import {
  streamTrades,
  streamOrderbookUpdate,
  streamSubaccountTrades,
  streamSubaccountOrders,
  streamMarketsMarkPrices,
  cancelMarketsMarkPrices,
  cancelSubaccountTradesStream,
  cancelSubaccountOrdersStream,
  streamSubaccountOrderHistory,
  cancelSubaccountOrderHistoryStream
} from "@/store/derivative/stream";
import {
  combineOrderbookRecords,
  marketHasRecentlyExpired
} from "@/app/utils/market";
import {
  tokenService,
  indexerOracleApi,
  indexerDerivativesApi,
  indexerRestDerivativesChronosApi
} from "@/app/Services";
import {
  IS_DEVNET,
  MARKETS_SLUGS,
  TRADE_MAX_SUBACCOUNT_ARRAY_SIZE
} from "@/app/utils/constants";
import { UiMarketTransformer } from "@/app/client/transformers/UiMarketTransformer";
const initialStateFactory = () => ({
  perpetualMarkets: [],
  expiryFuturesMarkets: [],
  recentlyExpiredMarkets: [],
  binaryOptionsMarkets: [],
  markets: [],
  marketsSummary: [],
  marketMarkPriceMap: {},
  orderbook: void 0,
  trades: [],
  subaccountTrades: [],
  subaccountTradesCount: 0,
  subaccountOrders: [],
  subaccountOrdersCount: 0,
  subaccountOrderHistory: [],
  subaccountOrderHistoryCount: 0,
  subaccountConditionalOrders: [],
  subaccountConditionalOrdersCount: 0
});
export const useDerivativeStore = defineStore("derivative", {
  state: () => initialStateFactory(),
  getters: {
    buys: (state) => {
      var _a;
      return ((_a = state.orderbook) == null ? void 0 : _a.buys) || [];
    },
    sells: (state) => {
      var _a;
      return ((_a = state.orderbook) == null ? void 0 : _a.sells) || [];
    },
    activeMarketIds: (state) => state.markets.filter(({ slug }) => MARKETS_SLUGS.futures.includes(slug)).map((m) => m.marketId),
    tradeableDenoms: (state) => state.markets.reduce((denoms, market) => {
      if (!denoms.includes(market.quoteDenom)) {
        denoms.push(market.quoteDenom);
      }
      return denoms;
    }, []),
    marketsWithSummary: (state) => state.markets.map((market) => ({
      market,
      summary: state.marketsSummary.find(
        (summary) => summary.marketId === market.marketId
      )
    })).filter((summary) => summary)
  },
  actions: {
    cancelOrder,
    batchCancelOrder,
    submitLimitOrder,
    submitMarketOrder,
    submitStopLimitOrder,
    submitStopMarketOrder,
    streamTrades,
    streamOrderbookUpdate,
    streamSubaccountTrades,
    streamSubaccountOrders,
    streamMarketsMarkPrices,
    cancelMarketsMarkPrices,
    cancelSubaccountOrdersStream,
    streamSubaccountOrderHistory,
    cancelSubaccountTradesStream,
    cancelSubaccountOrderHistoryStream,
    async init() {
      const derivativeStore = useDerivativeStore();
      const marketsAlreadyFetched = derivativeStore.markets.length;
      if (marketsAlreadyFetched) {
        await derivativeStore.fetchMarketsSummary();
        return;
      }
      const markets = await indexerDerivativesApi.fetchMarkets();
      const recentlyExpiredMarkets = (await indexerDerivativesApi.fetchMarkets({
        marketStatus: "expired"
      })).filter(marketHasRecentlyExpired);
      const marketsWithToken = await tokenService.toDerivativeMarketsWithToken(
        markets
      );
      const recentlyExpiredMarketsWithToken = await tokenService.toDerivativeMarketsWithToken(recentlyExpiredMarkets);
      const perpetualMarkets = marketsWithToken.filter((m) => m.isPerpetual);
      const expiryFuturesMarkets = marketsWithToken.filter(
        (m) => !m.isPerpetual
      );
      const uiPerpetualMarkets = UiDerivativeTransformer.perpetualMarketsToUiPerpetualMarkets(
        perpetualMarkets
      );
      const uiExpiryFuturesMarkets = UiDerivativeTransformer.expiryFuturesMarketsToUiExpiryFuturesMarkets(
        expiryFuturesMarkets
      );
      const uiRecentlyExpiredMarkets = UiDerivativeTransformer.expiryFuturesMarketsToUiExpiryFuturesMarkets(
        recentlyExpiredMarketsWithToken
      );
      const binaryOptionsMarkets = IS_DEVNET ? await indexerDerivativesApi.fetchBinaryOptionsMarkets() : [];
      const binaryOptionsMarketsWithToken = await tokenService.toBinaryOptionsMarketsWithToken(binaryOptionsMarkets);
      const uiBinaryOptionsMarkets = UiDerivativeTransformer.binaryOptionsMarketsToUiBinaryOptionsMarkets(
        binaryOptionsMarketsWithToken
      );
      const uiPerpetualMarketsWithToken = uiPerpetualMarkets.filter((market) => {
        return MARKETS_SLUGS.futures.includes(market.slug);
      }).sort((a, b) => {
        return MARKETS_SLUGS.futures.indexOf(a.slug) - MARKETS_SLUGS.futures.indexOf(b.slug);
      });
      const uiExpiryFuturesWithToken = uiExpiryFuturesMarkets.filter((market) => {
        return MARKETS_SLUGS.expiryFutures.includes(market.slug);
      }).sort((a, b) => {
        return MARKETS_SLUGS.expiryFutures.indexOf(a.slug) - MARKETS_SLUGS.expiryFutures.indexOf(b.slug);
      });
      const uiBinaryOptionsMarketsWithToken = uiBinaryOptionsMarkets.filter((market) => {
        return MARKETS_SLUGS.binaryOptions.includes(market.slug);
      }).sort((a, b) => {
        return MARKETS_SLUGS.binaryOptions.indexOf(a.slug) - MARKETS_SLUGS.binaryOptions.indexOf(b.slug);
      });
      derivativeStore.$patch({
        perpetualMarkets: uiPerpetualMarketsWithToken,
        expiryFuturesMarkets: uiExpiryFuturesWithToken,
        recentlyExpiredMarkets: uiRecentlyExpiredMarkets,
        binaryOptionsMarkets: uiBinaryOptionsMarketsWithToken,
        markets: [
          ...uiExpiryFuturesWithToken,
          ...uiPerpetualMarketsWithToken,
          ...uiBinaryOptionsMarketsWithToken
        ]
      });
      await derivativeStore.fetchMarketsSummary();
    },
    async getMarketMarkPrice(market) {
      const derivativeStore = useDerivativeStore();
      const oraclePrice = market.subType !== MarketType.BinaryOptions ? await indexerOracleApi.fetchOraclePrice({
        oracleType: market.oracleType,
        baseSymbol: market.oracleBase,
        quoteSymbol: market.oracleQuote
      }) : await indexerOracleApi.fetchOraclePriceNoThrow({
        baseSymbol: market.oracleSymbol,
        quoteSymbol: market.oracleProvider,
        oracleType: market.oracleType
      });
      derivativeStore.marketMarkPriceMap = {
        ...derivativeStore.marketMarkPriceMap,
        [market.marketId]: {
          marketId: market.marketId,
          price: oraclePrice.price
        }
      };
    },
    async fetchOrderbook(marketId) {
      var _a, _b, _c;
      const derivativeStore = useDerivativeStore();
      const currentOrderbookSequence = ((_a = derivativeStore.orderbook) == null ? void 0 : _a.sequence) || 0;
      const latestOrderbook = await indexerDerivativesApi.fetchOrderbookV2(
        marketId
      );
      if (latestOrderbook.sequence >= currentOrderbookSequence) {
        derivativeStore.orderbook = latestOrderbook;
      }
      derivativeStore.orderbook = {
        sequence: currentOrderbookSequence,
        buys: combineOrderbookRecords({
          isBuy: true,
          currentRecords: latestOrderbook.buys,
          updatedRecords: (_b = derivativeStore.orderbook) == null ? void 0 : _b.buys
        }),
        sells: combineOrderbookRecords({
          isBuy: false,
          currentRecords: latestOrderbook.sells,
          updatedRecords: (_c = derivativeStore.orderbook) == null ? void 0 : _c.sells
        })
      };
    },
    async fetchTrades({
      marketId,
      executionSide
    }) {
      const derivativeStore = useDerivativeStore();
      const { trades } = await indexerDerivativesApi.fetchTrades({
        marketIds: [marketId],
        executionSide
      });
      derivativeStore.$patch({
        trades
      });
    },
    async fetchSubaccountOrders(marketIds) {
      const derivativeStore = useDerivativeStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      const { orders, pagination } = await indexerDerivativesApi.fetchOrders({
        subaccountId,
        isConditional: false,
        marketIds: marketIds || derivativeStore.activeMarketIds,
        pagination: {
          limit: TRADE_MAX_SUBACCOUNT_ARRAY_SIZE
        }
      });
      derivativeStore.$patch({
        subaccountOrders: orders,
        subaccountOrdersCount: Math.min(
          pagination.total,
          TRADE_MAX_SUBACCOUNT_ARRAY_SIZE
        )
      });
    },
    async fetchSubaccountOrderHistory(options) {
      const derivativeStore = useDerivativeStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      const filters = options == null ? void 0 : options.filters;
      const { orderHistory, pagination } = await indexerDerivativesApi.fetchOrderHistory({
        subaccountId,
        direction: filters == null ? void 0 : filters.direction,
        pagination: options == null ? void 0 : options.pagination,
        isConditional: filters == null ? void 0 : filters.isConditional,
        executionTypes: filters == null ? void 0 : filters.executionTypes,
        marketIds: (filters == null ? void 0 : filters.marketIds) || derivativeStore.activeMarketIds,
        orderTypes: filters == null ? void 0 : filters.orderTypes
      });
      derivativeStore.$patch({
        subaccountOrderHistory: orderHistory,
        subaccountOrderHistoryCount: pagination.total
      });
    },
    async fetchSubaccountConditionalOrders(marketIds) {
      const derivativeStore = useDerivativeStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      const { orderHistory, pagination } = await indexerDerivativesApi.fetchOrderHistory({
        subaccountId,
        isConditional: true,
        state: DerivativeOrderState.Booked,
        marketIds: marketIds || derivativeStore.activeMarketIds,
        pagination: {
          limit: TRADE_MAX_SUBACCOUNT_ARRAY_SIZE
        }
      });
      derivativeStore.$patch({
        subaccountConditionalOrders: orderHistory,
        subaccountConditionalOrdersCount: Math.min(
          pagination.total,
          TRADE_MAX_SUBACCOUNT_ARRAY_SIZE
        )
      });
    },
    async fetchMarketsSummary() {
      const derivativeStore = useDerivativeStore();
      const { markets } = derivativeStore;
      const marketSummaries = await indexerRestDerivativesChronosApi.fetchMarketsSummary();
      const marketsWithoutMarketSummaries = marketSummaries.filter(
        ({ marketId }) => !markets.some((market) => market.marketId === marketId)
      );
      derivativeStore.$patch({
        marketsSummary: [
          ...marketSummaries.map(
            UiMarketTransformer.convertMarketSummaryToUiMarketSummary
          ),
          ...marketsWithoutMarketSummaries.map(
            ({ marketId }) => zeroDerivativeMarketSummary(marketId)
          )
        ]
      });
    },
    async fetchMarket(marketId) {
      const derivativeStore = useDerivativeStore();
      const updatedMarket = await indexerDerivativesApi.fetchMarket(
        marketId
      );
      const updatedMarketWithToken = await tokenService.toDerivativeMarketsWithToken([updatedMarket]);
      const marketIndex = derivativeStore.markets.findIndex(
        (m) => m.marketId === marketId
      );
      if (marketIndex) {
        const markets = [...derivativeStore.markets];
        markets[marketIndex] = {
          ...markets[marketIndex],
          ...updatedMarketWithToken
        };
        derivativeStore.$patch({
          markets
        });
      }
    },
    async fetchSubaccountTrades(options) {
      const derivativeStore = useDerivativeStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      const filters = options == null ? void 0 : options.filters;
      const { trades, pagination } = await indexerDerivativesApi.fetchTrades({
        direction: filters == null ? void 0 : filters.direction,
        subaccountId,
        pagination: options == null ? void 0 : options.pagination,
        executionTypes: filters == null ? void 0 : filters.executionTypes,
        marketIds: (filters == null ? void 0 : filters.marketIds) || derivativeStore.activeMarketIds
      });
      derivativeStore.$patch({
        subaccountTrades: trades,
        subaccountTradesCount: pagination.total
      });
    },
    cancelSubaccountStream() {
      const positionStore = usePositionStore();
      cancelSubaccountOrdersStream();
      cancelSubaccountTradesStream();
      cancelSubaccountOrderHistoryStream();
      positionStore.cancelSubaccountPositionsStream();
    },
    resetSubaccount() {
      const derivativeStore = useDerivativeStore();
      const initialState = initialStateFactory();
      derivativeStore.cancelSubaccountStream();
      derivativeStore.$patch({
        subaccountOrders: initialState.subaccountOrders,
        subaccountTrades: initialState.subaccountTrades,
        subaccountTradesCount: initialState.subaccountOrdersCount,
        subaccountOrdersCount: initialState.subaccountOrdersCount,
        subaccountOrderHistory: initialState.subaccountOrderHistory,
        subaccountOrderHistoryCount: initialState.subaccountOrderHistoryCount
      });
    },
    reset() {
      const derivativeStore = useDerivativeStore();
      const { trades, orderbook, subaccountTrades, subaccountOrders } = initialStateFactory();
      derivativeStore.$patch({
        trades,
        orderbook,
        subaccountTrades,
        subaccountOrders
      });
    }
  }
});
