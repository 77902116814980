"use strict";
import { defineStore } from "pinia";
import { INJ_COIN_GECKO_ID, UiBankTransformer } from "@injectivelabs/sdk-ui-ts";
import { bankApi, tokenPrice, tokenService } from "@/app/Services";
import { BTC_COIN_GECKO_ID } from "@/app/utils/constants";
const initialStateFactory = () => ({
  tokens: [],
  btcUsdPrice: 0,
  injUsdPrice: 0,
  tokenUsdPriceMap: {}
});
export const useTokenStore = defineStore("token", {
  state: () => initialStateFactory(),
  getters: {
    tokenUsdPrice: (state) => (coinGeckoId) => {
      return state.tokenUsdPriceMap[coinGeckoId] || 0;
    },
    tradeableTokens: (state) => {
      const derivativeStore = useDerivativeStore();
      const spotStore = useSpotStore();
      const tradeableDenoms = [
        .../* @__PURE__ */ new Set([
          ...derivativeStore.tradeableDenoms,
          ...spotStore.tradeableDenoms
        ])
      ];
      return state.tokens.filter((token) => {
        return tradeableDenoms.includes(token.denom);
      });
    }
  },
  actions: {
    async fetchTokenUsdPriceMap(coinGeckoIdList) {
      const tokenStore = useTokenStore();
      const tokenUsdPriceList = await Promise.all(
        coinGeckoIdList.map(async (coinGeckoId) => ({
          [coinGeckoId]: await tokenPrice.fetchUsdTokenPrice(coinGeckoId)
        }))
      );
      const tokenUsdPriceMap = tokenUsdPriceList.reduce(
        (prices, tokenUsdPriceMap2) => Object.assign(prices, tokenUsdPriceMap2),
        {}
      );
      tokenStore.$patch({
        tokenUsdPriceMap
      });
    },
    async fetchInjUsdPrice() {
      const tokenStore = useTokenStore();
      tokenStore.$patch({
        injUsdPrice: await tokenPrice.fetchUsdTokenPrice(INJ_COIN_GECKO_ID)
      });
    },
    async fetchBitcoinUsdPrice() {
      const tokenStore = useTokenStore();
      tokenStore.$patch({
        btcUsdPrice: await tokenPrice.fetchUsdTokenPrice(BTC_COIN_GECKO_ID)
      });
    },
    async fetchSupplyTokenMeta() {
      const tokenStore = useTokenStore();
      const { supply } = await bankApi.fetchTotalSupply({ limit: 200 });
      const { bankSupply, ibcBankSupply } = UiBankTransformer.supplyToUiSupply(supply);
      const tokens = await tokenService.toCoinsWithToken([
        ...bankSupply,
        ...ibcBankSupply
      ]);
      tokenStore.$patch({
        tokens
      });
    }
  }
});
