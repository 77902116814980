"use strict";
import {
  ChainCosmosErrorCode,
  ErrorType,
  isThrownException
} from "@injectivelabs/exceptions";
import { StatusCodes } from "http-status-codes";
import { defineNuxtPlugin } from "#imports";
import { IS_PRODUCTION, BUGSNAG_KEY } from "@/app/utils/constants";
import { Modal } from "@/types/enums";
const reportToUser = (error) => {
  const { error: errorToast } = useNotifications();
  if (error.type === ErrorType.HttpRequest && error.code === StatusCodes.REQUEST_TOO_LONG) {
    return;
  }
  errorToast({ title: error.message });
};
const reportToBugSnag = (error) => {
  if (!IS_PRODUCTION) {
    console.warn(error.toCompactError().message);
    console.error(error);
    return;
  }
  if ([ErrorType.Unspecified, ErrorType.WalletError].includes(error.type)) {
    console.warn(error.toCompactError().message);
    console.error(error);
    return;
  }
  if (BUGSNAG_KEY) {
    useBugsnag().notify(error, (event) => {
      event.errors[0].errorClass = error.errorClass || error.name;
      if (useWalletStore().isUserWalletConnected) {
        event.setUser(useWalletStore().injectiveAddress);
      }
      event.addMetadata("error-context", error.toObject());
    });
  }
};
const reportUnknownErrorToBugsnag = (error) => {
  if (!IS_PRODUCTION) {
    console.error({ error, stack: error.stack });
  }
  const newError = new Error(
    `The ${error.message} is not handled as an Exception - ${error.stack}`
  );
  console.warn(newError.message, newError.stack);
  if (BUGSNAG_KEY) {
    useBugsnag().notify(newError);
  }
};
const handleInsufficientGas = (error) => {
  const bankStore = useBankStore();
  const modalStore = useModalStore();
  if (bankStore.hasEnoughInjForGas) {
    return;
  }
  if (error.contextCode !== ChainCosmosErrorCode.ErrInsufficientFee) {
    return;
  }
  modalStore.openModal({ type: Modal.InsufficientInjForGas });
};
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    console.warn(error, context, error.stack);
  };
  window.onunhandledrejection = function(event) {
    const error = event.reason;
    if (!IS_PRODUCTION) {
      return;
    }
    if (!isThrownException(error)) {
      reportUnknownErrorToBugsnag(error);
    } else {
      reportToBugSnag(error);
    }
  };
  const errorHandler = (error) => {
    if (!isThrownException(error)) {
      return reportUnknownErrorToBugsnag(error);
    }
    reportToUser(error);
    if (IS_PRODUCTION) {
      reportToBugSnag(error);
    }
    console.warn(error.toObject());
    handleInsufficientGas(error);
  };
  return {
    provide: {
      onError: errorHandler
    }
  };
});
