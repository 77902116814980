"use strict";
import { defineStore } from "pinia";
import { indexerAccountApi, indexerDerivativesApi } from "@/app/Services";
import {
  streamSpotSubaccountTrades,
  streamSpotSubaccountOrderHistory,
  streamDerivativeSubaccountTrades,
  streamDerivativeSubaccountOrderHistory
} from "@/store/activity/stream";
const initialStateFactory = () => ({
  subaccountFundingPayments: [],
  tradingRewardsHistory: [],
  subaccountFundingPaymentsCount: 0,
  latestDerivativeOrderHistory: void 0,
  latestDerivativeTrade: void 0,
  latestSpotOrderHistory: void 0,
  latestSpotTrade: void 0
});
export const useActivityStore = defineStore("activity", {
  state: () => initialStateFactory(),
  actions: {
    streamDerivativeSubaccountOrderHistory,
    streamDerivativeSubaccountTrades,
    streamSpotSubaccountOrderHistory,
    streamSpotSubaccountTrades,
    async fetchTradingRewardsHistory() {
      const activityStore = useActivityStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected, injectiveAddress } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId || !injectiveAddress) {
        return;
      }
      activityStore.$patch({
        tradingRewardsHistory: await indexerAccountApi.fetchRewards({
          address: injectiveAddress,
          epoch: -1
        })
      });
    },
    async fetchSubaccountFundingPayments(options) {
      const activityStore = useActivityStore();
      const derivativeStore = useDerivativeStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      const filters = options == null ? void 0 : options.filters;
      const { fundingPayments: subaccountFundingPayments, pagination } = await indexerDerivativesApi.fetchFundingPayments({
        subaccountId,
        marketIds: (filters == null ? void 0 : filters.marketIds) || derivativeStore.activeMarketIds,
        pagination: options == null ? void 0 : options.pagination
      });
      activityStore.$patch({
        subaccountFundingPayments,
        subaccountFundingPaymentsCount: pagination.total
      });
    }
  }
});
