import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.mjs";
import cancel_45stream_45global from "/opt/build/repo/middleware/cancelStream.global.ts";
import helix_45redirect_45global from "/opt/build/repo/middleware/helix-redirect.global.ts";
import maintenance_45global from "/opt/build/repo/middleware/maintenance.global.ts";
export const globalMiddleware = [
  validate,
  cancel_45stream_45global,
  helix_45redirect_45global,
  maintenance_45global
]
export const namedMiddleware = {
  connected: () => import("/opt/build/repo/middleware/connected.ts"),
  leaderboard: () => import("/opt/build/repo/middleware/leaderboard.ts"),
  markets: () => import("/opt/build/repo/middleware/markets.ts")
}