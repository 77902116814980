import { default as _47opt_47build_47repo_47pages_47NFT_46vueMeta } from "/opt/build/repo/pages/NFT.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47account_46vueMeta } from "/opt/build/repo/pages/account.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47activity_46vueMeta } from "/opt/build/repo/pages/activity.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47convert_46vueMeta } from "/opt/build/repo/pages/convert.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47fee_45discounts_46vueMeta } from "/opt/build/repo/pages/fee-discounts.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta } from "/opt/build/repo/pages/futures/[futures].vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47helix_46vueMeta } from "/opt/build/repo/pages/helix.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47index_46vueMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47leaderboard_46vueMeta } from "/opt/build/repo/pages/leaderboard.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47maintenance_46vueMeta } from "/opt/build/repo/pages/maintenance.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47market_47_91market_93_46vueMeta } from "/opt/build/repo/pages/market/[market].vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47markets_46vueMeta } from "/opt/build/repo/pages/markets.vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta } from "/opt/build/repo/pages/spot/[spot].vue?macro=true";
import { default as _47opt_47build_47repo_47pages_47trade_45and_45earn_46vueMeta } from "/opt/build/repo/pages/trade-and-earn.vue?macro=true";
export default [
  {
    name: _47opt_47build_47repo_47pages_47NFT_46vueMeta?.name ?? "NFT",
    path: _47opt_47build_47repo_47pages_47NFT_46vueMeta?.path ?? "/NFT",
    file: "/opt/build/repo/pages/NFT.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47NFT_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47NFT_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47NFT_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/NFT.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47account_46vueMeta?.name ?? "account",
    path: _47opt_47build_47repo_47pages_47account_46vueMeta?.path ?? "/account",
    file: "/opt/build/repo/pages/account.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47account_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47account_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47account_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47activity_46vueMeta?.name ?? "activity",
    path: _47opt_47build_47repo_47pages_47activity_46vueMeta?.path ?? "/activity",
    file: "/opt/build/repo/pages/activity.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47activity_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47activity_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47activity_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47convert_46vueMeta?.name ?? "convert",
    path: _47opt_47build_47repo_47pages_47convert_46vueMeta?.path ?? "/convert",
    file: "/opt/build/repo/pages/convert.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47convert_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47convert_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47convert_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/convert.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47fee_45discounts_46vueMeta?.name ?? "fee-discounts",
    path: _47opt_47build_47repo_47pages_47fee_45discounts_46vueMeta?.path ?? "/fee-discounts",
    file: "/opt/build/repo/pages/fee-discounts.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47fee_45discounts_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47fee_45discounts_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47fee_45discounts_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/fee-discounts.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.name ?? "futures-futures",
    path: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.path ?? "/futures/:futures",
    file: "/opt/build/repo/pages/futures/[futures].vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47helix_46vueMeta?.name ?? "helix",
    path: _47opt_47build_47repo_47pages_47helix_46vueMeta?.path ?? "/helix",
    file: "/opt/build/repo/pages/helix.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47helix_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47helix_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47helix_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/helix.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47index_46vueMeta?.name ?? "index",
    path: _47opt_47build_47repo_47pages_47index_46vueMeta?.path ?? "/",
    file: "/opt/build/repo/pages/index.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47index_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47index_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47leaderboard_46vueMeta?.name ?? "leaderboard",
    path: _47opt_47build_47repo_47pages_47leaderboard_46vueMeta?.path ?? "/leaderboard",
    file: "/opt/build/repo/pages/leaderboard.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47leaderboard_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47leaderboard_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47leaderboard_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47maintenance_46vueMeta?.name ?? "maintenance",
    path: _47opt_47build_47repo_47pages_47maintenance_46vueMeta?.path ?? "/maintenance",
    file: "/opt/build/repo/pages/maintenance.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47maintenance_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47maintenance_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47maintenance_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47market_47_91market_93_46vueMeta?.name ?? "market-market",
    path: _47opt_47build_47repo_47pages_47market_47_91market_93_46vueMeta?.path ?? "/market/:market",
    file: "/opt/build/repo/pages/market/[market].vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47market_47_91market_93_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47market_47_91market_93_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47market_47_91market_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/market/[market].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47markets_46vueMeta?.name ?? "markets",
    path: _47opt_47build_47repo_47pages_47markets_46vueMeta?.path ?? "/markets",
    file: "/opt/build/repo/pages/markets.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47markets_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47markets_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47markets_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/markets.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta?.name ?? "spot-spot",
    path: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta?.path ?? "/spot/:spot",
    file: "/opt/build/repo/pages/spot/[spot].vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/spot/[spot].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47trade_45and_45earn_46vueMeta?.name ?? "trade-and-earn",
    path: _47opt_47build_47repo_47pages_47trade_45and_45earn_46vueMeta?.path ?? "/trade-and-earn",
    file: "/opt/build/repo/pages/trade-and-earn.vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47trade_45and_45earn_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47trade_45and_45earn_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47trade_45and_45earn_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/trade-and-earn.vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.name ?? "futures",
    path: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.path ?? "/futures",
    file: "/opt/build/repo/pages/futures/[futures].vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta?.name ?? "spot",
    path: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta?.path ?? "/spot",
    file: "/opt/build/repo/pages/spot/[spot].vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47spot_47_91spot_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/spot/[spot].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.name ?? "binary-options-binaryOption",
    path: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.path ?? "/binary-options/:binaryOption",
    file: "/opt/build/repo/pages/futures/[futures].vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.name ?? "derivative-derivative",
    path: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.path ?? "/derivative/:derivative",
    file: "/opt/build/repo/pages/futures/[futures].vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/futures/[futures].vue").then(m => m.default || m)
  },
  {
    name: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.name ?? "perpetual-perpetual",
    path: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.path ?? "/perpetual/:perpetual",
    file: "/opt/build/repo/pages/futures/[futures].vue",
    children: [],
    meta: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta,
    alias: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.alias || [],
    redirect: _47opt_47build_47repo_47pages_47futures_47_91futures_93_46vueMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/futures/[futures].vue").then(m => m.default || m)
  }
]