"use strict";
import { Wallet } from "@injectivelabs/wallet-ts";
import { defineNuxtPlugin } from "#imports";
import { localStorage } from "@/app/Services";
import { AppState, OrderbookLayout, TradingLayout } from "@/types";
const stateToPersist = {
  app: {
    userState: {
      vpnOrProxyUsageValidationTimestamp: 0,
      favoriteMarkets: [],
      geoLocation: {
        continent: "",
        country: ""
      },
      orderbookLayout: OrderbookLayout.Default,
      tradingLayout: TradingLayout.Left,
      ninjaPassWinnerModalViewed: false,
      userFeedbackModalViewed: false,
      bannersViewed: []
    }
  },
  bank: {
    defaultSubaccountId: ""
  },
  wallet: {
    wallet: Wallet.Metamask,
    addresses: "",
    address: "",
    injectiveAddress: "",
    addressConfirmation: ""
  }
};
const actionsThatSetAppStateToBusy = [
  "bank/deposit",
  "bank/transfer",
  "peggy/deposit",
  "bank/withdraw",
  "peggy/withdraw",
  "spot/cancelOrder",
  "spot/batchCancelOrder",
  "spot/submitLimitOrder",
  "derivative/cancelOrder",
  "position/closePosition",
  "spot/submitMarketOrder",
  "peggy/setTokenAllowance",
  "position/closeAllPosition",
  "spot/submitStopLimitOrder",
  "spot/submitStopMarketOrder",
  "derivative/batchCancelOrder",
  "derivative/submitLimitOrder",
  "derivative/submitMarketOrder",
  "position/addMarginToPosition",
  "activity/batchCancelSpotOrders",
  "derivative/submitStopLimitOrder",
  "derivative/submitStopMarketOrder",
  "activity/batchCancelDerivativeOrders",
  "position/closePositionAndReduceOnlyOrders"
];
const persistState = (mutation, state) => {
  if (!stateToPersist[mutation.storeId]) {
    return;
  }
  const keysToPersist = Object.keys(stateToPersist[mutation.storeId]);
  if (!mutation.payload) {
    return;
  }
  const shouldPersistState = keysToPersist.length > 0 && Object.keys(mutation.payload).some((key) => {
    return keysToPersist.includes(key);
  });
  if (!shouldPersistState) {
    return;
  }
  const updatedState = keysToPersist.reduce((stateObj, key) => {
    return {
      ...stateObj,
      [key]: mutation.payload[key] || state[key]
    };
  }, {});
  const existingState = localStorage.get("state") || {};
  localStorage.set("state", {
    ...stateToPersist,
    ...existingState,
    [mutation.storeId]: {
      ...updatedState
    }
  });
};
function piniaStoreSubscriber({ store }) {
  const localState = localStorage.get("state");
  const appStore = useAppStore();
  if (localState[store.$id]) {
    store.$state = { ...store.$state, ...localState[store.$id] };
  }
  store.$subscribe(persistState);
  store.$onAction(({ name, store: { $id }, after, onError }) => {
    after(() => {
      const type = `${$id}/${name}`;
      if (actionsThatSetAppStateToBusy.includes(type)) {
        appStore.$patch({
          state: AppState.Idle
        });
      }
    });
    onError(() => {
      const type = `${$id}/${name}`;
      if (actionsThatSetAppStateToBusy.includes(type)) {
        appStore.$patch({
          state: AppState.Idle
        });
      }
    });
  }, true);
}
export default defineNuxtPlugin(
  ({
    vueApp: {
      config: { globalProperties }
    }
  }) => {
    globalProperties.$pinia.use(piniaStoreSubscriber);
  }
);
