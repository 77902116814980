"use strict";
import { defineStore } from "pinia";
import {
  UiSpotTransformer,
  zeroSpotMarketSummary
} from "@injectivelabs/sdk-ui-ts";
import {
  streamTrades,
  cancelTradesStream,
  streamOrderbookUpdate,
  streamSubaccountTrades,
  streamSubaccountOrders,
  cancelOrderbookUpdateStream,
  cancelSubaccountOrdersStream,
  cancelSubaccountTradesStream,
  streamSubaccountOrderHistory,
  cancelSubaccountOrdersHistoryStream
} from "@/store/spot/stream";
import {
  cancelOrder,
  batchCancelOrder,
  submitLimitOrder,
  submitMarketOrder,
  submitStopLimitOrder,
  submitStopMarketOrder
} from "@/store/spot/message";
import {
  tokenService,
  indexerSpotApi,
  indexerRestSpotChronosApi
} from "@/app/Services";
import {
  MARKETS_SLUGS,
  TRADE_MAX_SUBACCOUNT_ARRAY_SIZE
} from "@/app/utils/constants";
import { combineOrderbookRecords } from "@/app/utils/market";
import { UiMarketTransformer } from "@/app/client/transformers/UiMarketTransformer";
const initialStateFactory = () => ({
  markets: [],
  marketsSummary: [],
  orderbook: void 0,
  trades: [],
  subaccountTrades: [],
  subaccountTradesCount: 0,
  subaccountOrders: [],
  subaccountOrdersCount: 0,
  subaccountOrderHistory: [],
  subaccountOrderHistoryCount: 0,
  usdcConversionModalMarkets: []
});
export const useSpotStore = defineStore("spot", {
  state: () => initialStateFactory(),
  getters: {
    buys: (state) => {
      var _a;
      return ((_a = state.orderbook) == null ? void 0 : _a.buys) || [];
    },
    sells: (state) => {
      var _a;
      return ((_a = state.orderbook) == null ? void 0 : _a.sells) || [];
    },
    activeMarketIds: (state) => state.markets.filter(({ slug }) => MARKETS_SLUGS.spot.includes(slug)).map((m) => m.marketId),
    tradeableDenoms: (state) => [...state.usdcConversionModalMarkets, ...state.markets].reduce(
      (denoms, market) => {
        if (!denoms.includes(market.baseDenom)) {
          denoms.push(market.baseDenom);
        }
        if (!denoms.includes(market.quoteDenom)) {
          denoms.push(market.quoteDenom);
        }
        return denoms;
      },
      []
    ),
    marketsWithSummary: (state) => state.markets.map((market) => ({
      market,
      summary: state.marketsSummary.find(
        (summary) => summary.marketId === market.marketId
      )
    })).filter((summary) => summary)
  },
  actions: {
    streamTrades,
    cancelTradesStream,
    streamOrderbookUpdate,
    streamSubaccountOrders,
    streamSubaccountTrades,
    cancelOrderbookUpdateStream,
    streamSubaccountOrderHistory,
    cancelOrder,
    batchCancelOrder,
    submitLimitOrder,
    submitMarketOrder,
    submitStopLimitOrder,
    submitStopMarketOrder,
    async init() {
      const spotStore = useSpotStore();
      const marketsAlreadyFetched = spotStore.markets.length;
      if (marketsAlreadyFetched) {
        await spotStore.fetchMarketsSummary();
        return;
      }
      const markets = await indexerSpotApi.fetchMarkets();
      const marketsWithToken = await tokenService.toSpotMarketsWithToken(
        markets
      );
      const uiMarkets = UiSpotTransformer.spotMarketsToUiSpotMarkets(marketsWithToken);
      const uiMarketsWithToken = uiMarkets.filter((market) => {
        return MARKETS_SLUGS.spot.includes(market.slug);
      }).sort((a, b) => {
        return MARKETS_SLUGS.spot.indexOf(a.slug) - MARKETS_SLUGS.spot.indexOf(b.slug);
      });
      spotStore.$patch({
        markets: uiMarketsWithToken
      });
      await spotStore.fetchMarketsSummary();
    },
    async fetchUsdcConversionMarkets() {
      const spotStore = useSpotStore();
      const markets = await indexerSpotApi.fetchMarkets();
      const marketsWithToken = await tokenService.toSpotMarketsWithToken(
        markets
      );
      const uiMarkets = UiSpotTransformer.spotMarketsToUiSpotMarkets(marketsWithToken);
      const usdcConversionModalMarketsWithToken = uiMarkets.filter((market) => {
        return MARKETS_SLUGS.usdcConversionModalMarkets.includes(market.slug);
      }).sort((a, b) => {
        return MARKETS_SLUGS.usdcConversionModalMarkets.indexOf(a.slug) - MARKETS_SLUGS.usdcConversionModalMarkets.indexOf(b.slug);
      });
      spotStore.$patch({
        usdcConversionModalMarkets: usdcConversionModalMarketsWithToken
      });
    },
    async fetchSubaccountOrders(marketIds) {
      const spotStore = useSpotStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      const { orders, pagination } = await indexerSpotApi.fetchOrders({
        subaccountId,
        marketIds: marketIds || spotStore.activeMarketIds,
        pagination: {
          limit: TRADE_MAX_SUBACCOUNT_ARRAY_SIZE
        }
      });
      spotStore.$patch({
        subaccountOrders: orders,
        subaccountOrdersCount: Math.min(
          pagination.total,
          TRADE_MAX_SUBACCOUNT_ARRAY_SIZE
        )
      });
    },
    async fetchSubaccountOrderHistory(options) {
      const spotStore = useSpotStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      const filters = options == null ? void 0 : options.filters;
      const { orderHistory, pagination } = await indexerSpotApi.fetchOrderHistory({
        subaccountId,
        direction: filters == null ? void 0 : filters.direction,
        pagination: options == null ? void 0 : options.pagination,
        isConditional: filters == null ? void 0 : filters.isConditional,
        marketIds: (filters == null ? void 0 : filters.marketIds) || spotStore.activeMarketIds,
        orderTypes: filters == null ? void 0 : filters.orderTypes,
        executionTypes: filters == null ? void 0 : filters.executionTypes
      });
      spotStore.$patch({
        subaccountOrderHistory: orderHistory,
        subaccountOrderHistoryCount: pagination.total
      });
    },
    async fetchOrderbook(marketId) {
      var _a, _b, _c;
      const spotStore = useSpotStore();
      const currentOrderbookSequence = ((_a = spotStore.orderbook) == null ? void 0 : _a.sequence) || 0;
      const latestOrderbook = await indexerSpotApi.fetchOrderbookV2(marketId);
      if (latestOrderbook.sequence >= currentOrderbookSequence) {
        spotStore.orderbook = latestOrderbook;
      }
      spotStore.orderbook = {
        sequence: currentOrderbookSequence,
        buys: combineOrderbookRecords({
          isBuy: true,
          currentRecords: (_b = spotStore.orderbook) == null ? void 0 : _b.buys,
          updatedRecords: latestOrderbook.buys
        }),
        sells: combineOrderbookRecords({
          isBuy: false,
          currentRecords: (_c = spotStore.orderbook) == null ? void 0 : _c.sells,
          updatedRecords: latestOrderbook.sells
        })
      };
    },
    async fetchTrades({
      marketId,
      executionSide
    }) {
      const spotStore = useSpotStore();
      const { trades } = await indexerSpotApi.fetchTrades({
        marketIds: [marketId],
        executionSide
      });
      spotStore.$patch({
        trades
      });
    },
    async fetchSubaccountTrades(options) {
      const spotStore = useSpotStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      const filters = options == null ? void 0 : options.filters;
      const { trades, pagination } = await indexerSpotApi.fetchTrades({
        subaccountId,
        direction: filters == null ? void 0 : filters.direction,
        pagination: options == null ? void 0 : options.pagination,
        marketIds: (filters == null ? void 0 : filters.marketIds) || spotStore.activeMarketIds,
        executionTypes: filters == null ? void 0 : filters.executionTypes
      });
      spotStore.$patch({
        subaccountTrades: trades,
        subaccountTradesCount: pagination.total
      });
    },
    async fetchMarketsSummary() {
      const spotStore = useSpotStore();
      const { markets } = spotStore;
      const marketSummaries = await indexerRestSpotChronosApi.fetchMarketsSummary();
      const marketsWithoutMarketSummaries = marketSummaries.filter(
        ({ marketId }) => !markets.some((market) => market.marketId === marketId)
      );
      spotStore.$patch({
        marketsSummary: [
          ...marketSummaries.map(
            UiMarketTransformer.convertMarketSummaryToUiMarketSummary
          ),
          ...marketsWithoutMarketSummaries.map(
            ({ marketId }) => zeroSpotMarketSummary(marketId)
          )
        ]
      });
    },
    cancelSubaccountStream() {
      cancelSubaccountOrdersStream();
      cancelSubaccountTradesStream();
      cancelSubaccountOrdersHistoryStream();
    },
    resetSubaccount() {
      const spotStore = useSpotStore();
      const {
        subaccountOrders,
        subaccountTrades,
        subaccountOrdersCount,
        subaccountTradesCount,
        subaccountOrderHistory,
        subaccountOrderHistoryCount
      } = initialStateFactory();
      spotStore.cancelSubaccountStream();
      spotStore.$patch({
        subaccountOrders,
        subaccountTrades,
        subaccountTradesCount,
        subaccountOrdersCount,
        subaccountOrderHistory,
        subaccountOrderHistoryCount
      });
    },
    reset() {
      const spotStore = useSpotStore();
      const { trades, orderbook, subaccountOrders, subaccountTrades } = initialStateFactory();
      spotStore.$patch({
        trades,
        orderbook,
        subaccountOrders,
        subaccountTrades
      });
    }
  }
});
