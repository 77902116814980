import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/router.mjs";
import plugins_amplitude_ts_qcoZpE5aTU from "/opt/build/repo/plugins/amplitude.ts";
import plugins_handlers_ts_oZWLY9pUCB from "/opt/build/repo/plugins/handlers.ts";
import plugins_i18n_ts_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import plugins_plyr_ts_m1vubbxwTm from "/opt/build/repo/plugins/plyr.ts";
import plugins_store_ts_9xNuDHGAVU from "/opt/build/repo/plugins/store.ts";
import plugins_validation_ts_30a3L3OexF from "/opt/build/repo/plugins/validation.ts";
export default [
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  plugins_amplitude_ts_qcoZpE5aTU,
  plugins_handlers_ts_oZWLY9pUCB,
  plugins_i18n_ts_VfGcjrvSkj,
  plugins_plyr_ts_m1vubbxwTm,
  plugins_store_ts_9xNuDHGAVU,
  plugins_validation_ts_30a3L3OexF
]