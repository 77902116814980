"use strict";
import { defineStore } from "pinia";
import {
  ZERO_IN_BASE,
  zeroSpotMarketSummary,
  UiMarketsHistoryTransformer
} from "@injectivelabs/sdk-ui-ts";
import {
  denomClient,
  exchangeApi,
  indexerRestMarketChronosApi
} from "@/app/Services";
import { upcomingMarkets, deprecatedMarkets } from "@/app/data/market";
const initialStateFactory = () => ({
  params: void 0,
  feeDiscountSchedule: void 0,
  feeDiscountAccountInfo: void 0,
  tradingRewardsCampaign: void 0,
  tradeRewardsPoints: [],
  pendingTradeRewardsPoints: [],
  upcomingMarkets,
  upcomingMarketsSummaries: upcomingMarkets.map(
    (m) => zeroSpotMarketSummary(m.marketId)
  ),
  deprecatedMarkets,
  deprecatedMarketsSummaries: deprecatedMarkets.map(
    (m) => zeroSpotMarketSummary(m.marketId)
  ),
  marketsHistory: []
});
export const useExchangeStore = defineStore("exchange", {
  state: () => initialStateFactory(),
  getters: {
    deprecatedMarketsWithSummary: (state) => state.deprecatedMarkets.map((market) => ({
      market,
      summary: state.deprecatedMarketsSummaries.find(
        (summary) => summary.marketId === market.marketId
      )
    })).filter((summary) => summary),
    upcomingMarketsWithSummary: (state) => state.upcomingMarkets.map((market) => ({
      market,
      summary: state.upcomingMarketsSummaries.find(
        (summary) => summary.marketId === market.marketId
      )
    })).filter((summary) => summary)
  },
  actions: {
    async initFeeDiscounts() {
      const exchangeStore = useExchangeStore();
      await exchangeStore.fetchFeeDiscountAccountInfo();
    },
    async initTradeAndEarn() {
      const exchangeStore = useExchangeStore();
      await exchangeStore.fetchTradeRewardPoints();
      await exchangeStore.fetchPendingTradeRewardPoints();
    },
    async fetchParams() {
      const exchangeStore = useExchangeStore();
      exchangeStore.$patch({
        params: await exchangeApi.fetchModuleParams()
      });
    },
    async fetchFeeDiscountSchedule() {
      const exchangeStore = useExchangeStore();
      const feeDiscountSchedule = await exchangeApi.fetchFeeDiscountSchedule();
      if (feeDiscountSchedule) {
        const quoteTokenMeta = await Promise.all(
          feeDiscountSchedule.quoteDenomsList.map(
            async (denom) => await denomClient.getDenomToken(denom)
          )
        );
        const feeDiscountScheduleWithToken = {
          ...feeDiscountSchedule,
          quoteTokenMeta
        };
        exchangeStore.$patch({
          feeDiscountSchedule: feeDiscountScheduleWithToken
        });
      }
    },
    async fetchFeeDiscountAccountInfo() {
      const exchangeStore = useExchangeStore();
      const { isUserWalletConnected, injectiveAddress } = useWalletStore();
      if (!isUserWalletConnected || !injectiveAddress) {
        return;
      }
      const feeDiscountAccountInfo = await exchangeApi.fetchFeeDiscountAccountInfo(injectiveAddress);
      if (feeDiscountAccountInfo) {
        exchangeStore.$patch({
          feeDiscountAccountInfo
        });
      }
    },
    async fetchTradingRewardsCampaign() {
      const exchangeStore = useExchangeStore();
      const tradingRewardsCampaign = await exchangeApi.fetchTradingRewardsCampaign();
      if (tradingRewardsCampaign) {
        const quoteDenomsList = tradingRewardsCampaign.tradingRewardCampaignInfo ? tradingRewardsCampaign.tradingRewardCampaignInfo.quoteDenomsList : [];
        const quoteSymbolsList = (await Promise.all(
          quoteDenomsList.map(
            async (denom) => await denomClient.getDenomToken(denom)
          )
        )).filter((token) => token).map((token) => token.symbol);
        const tradingRewardCampaignInfo = {
          ...tradingRewardsCampaign.tradingRewardCampaignInfo,
          quoteSymbolsList
        };
        const tradingRewardsCampaignWithToken = {
          ...tradingRewardsCampaign,
          tradingRewardCampaignInfo
        };
        exchangeStore.$patch({
          tradingRewardsCampaign: tradingRewardsCampaignWithToken
        });
      }
    },
    async fetchTradeRewardPoints() {
      const exchangeStore = useExchangeStore();
      const { isUserWalletConnected, injectiveAddress } = useWalletStore();
      if (!isUserWalletConnected || !injectiveAddress) {
        return;
      }
      exchangeStore.$patch({
        tradeRewardsPoints: await exchangeApi.fetchTradeRewardPoints([
          injectiveAddress
        ])
      });
    },
    async fetchPendingTradeRewardPoints() {
      const exchangeStore = useExchangeStore();
      const { isUserWalletConnected, injectiveAddress } = useWalletStore();
      if (!isUserWalletConnected || !injectiveAddress) {
        return;
      }
      const { params, tradingRewardsCampaign } = exchangeStore;
      if (!params || !tradingRewardsCampaign) {
        return;
      }
      const pendingRewardsList = tradingRewardsCampaign.pendingTradingRewardPoolCampaignScheduleList;
      if (pendingRewardsList.length === 0) {
        return;
      }
      const rewards = await Promise.all(
        pendingRewardsList.map(async (pendingReward) => {
          const rewards2 = await exchangeApi.fetchPendingTradeRewardPoints(
            [injectiveAddress],
            pendingReward.startTimestamp
          );
          return rewards2.reduce((total, reward) => {
            return total.plus(reward);
          }, ZERO_IN_BASE).toFixed();
        })
      );
      exchangeStore.$patch({
        pendingTradeRewardsPoints: rewards
      });
    },
    async getMarketsHistory({
      marketIds,
      resolution,
      countback
    }) {
      const exchangeStore = useExchangeStore();
      const marketHistoryAlreadyExists = marketIds.every((marketId) => {
        return exchangeStore.marketsHistory.find(
          (marketHistory) => {
            return marketHistory.marketId === marketId;
          }
        );
      });
      if (marketHistoryAlreadyExists) {
        return;
      }
      const marketsHistory = await indexerRestMarketChronosApi.fetchMarketsHistory({
        marketIds,
        resolution,
        countback
      });
      const marketsHistoryToUiMarketsHistory = UiMarketsHistoryTransformer.marketsHistoryToUiMarketsHistory(
        marketsHistory
      );
      exchangeStore.$patch({
        marketsHistory: [
          ...exchangeStore.marketsHistory,
          ...marketsHistoryToUiMarketsHistory
        ]
      });
    },
    reset() {
      const exchangeStore = useExchangeStore();
      const {
        tradeRewardsPoints,
        feeDiscountSchedule,
        feeDiscountAccountInfo,
        tradingRewardsCampaign,
        pendingTradeRewardsPoints
      } = initialStateFactory();
      exchangeStore.$patch({
        tradeRewardsPoints,
        feeDiscountSchedule,
        feeDiscountAccountInfo,
        tradingRewardsCampaign,
        pendingTradeRewardsPoints
      });
    }
  }
});
