"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "container" };
const _hoisted_2 = { class: "w-full mx-auto 3xl:w-11/12 4xl:w-10/12 relative" };
import { ZERO_IN_BASE } from "@injectivelabs/sdk-ui-ts";
import { TradeDirection } from "@injectivelabs/ts-types";
import {
  Status,
  StatusType,
  BigNumberInWei,
  BigNumberInBase
} from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "account",
  setup(__props) {
    definePageMeta({
      middleware: ["connected"]
    });
    const appStore = useAppStore();
    const bankStore = useBankStore();
    const spotStore = useSpotStore();
    const walletStore = useWalletStore();
    const positionStore = usePositionStore();
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const { accountBalancesWithTokenInBases } = useBalance();
    const status = reactive(new Status(StatusType.Loading));
    const totalPositionsPnlByQuoteDenom = computed(() => {
      return positionStore.subaccountPositions.reduce((positions, p) => {
        const market = derivativeStore.markets.find(
          (m) => m.marketId === p.marketId
        );
        if (!market) {
          return positions;
        }
        const quoteDenom = market.quoteDenom.toLowerCase();
        if (!positions[quoteDenom]) {
          positions[quoteDenom] = ZERO_IN_BASE;
        }
        const price = new BigNumberInWei(p.entryPrice).toBase(
          market.quoteToken.decimals
        );
        const markPrice = new BigNumberInWei(p.markPrice).toBase(
          market.quoteToken.decimals
        );
        const pnl = new BigNumberInBase(p.quantity).times(markPrice.minus(price)).times(p.direction === TradeDirection.Long ? 1 : -1);
        positions[quoteDenom] = positions[quoteDenom].plus(pnl);
        return positions;
      }, {});
    });
    const totalPositionsMarginByQuoteDenom = computed(
      () => positionStore.subaccountPositions.reduce((positions, position) => {
        const market = derivativeStore.markets.find(
          (m) => m.marketId === position.marketId
        );
        if (!market) {
          return positions;
        }
        const quoteDenom = market.quoteDenom.toLowerCase();
        if (!positions[quoteDenom]) {
          positions[quoteDenom] = ZERO_IN_BASE;
        }
        positions[quoteDenom] = positions[quoteDenom].plus(
          new BigNumberInWei(position.margin).toBase(market.quoteToken.decimals)
        );
        return positions;
      }, {})
    );
    const balances = computed(() => {
      return accountBalancesWithTokenInBases.value.map((balance) => {
        const denom = balance.denom.toLowerCase();
        const margin = totalPositionsMarginByQuoteDenom.value[denom] || ZERO_IN_BASE;
        const pnl = totalPositionsPnlByQuoteDenom.value[denom] || ZERO_IN_BASE;
        const accountTotalBalance = new BigNumberInBase(balance.accountTotalBalance).plus(margin).plus(pnl);
        const accountTotalBalanceInUsd = accountTotalBalance.times(balance.usdPrice);
        return {
          ...balance,
          accountTotalBalance: accountTotalBalance.toFixed(),
          accountTotalBalanceInUsd: accountTotalBalanceInUsd.toFixed(),
          unrealizedPnl: margin.plus(pnl).toFixed()
        };
      });
    });
    onMounted(() => {
      status.setLoading();
      Promise.all([
        spotStore.init(),
        derivativeStore.init(),
        bankStore.streamBankBalance(),
        bankStore.fetchAccountPortfolio(),
        bankStore.streamSubaccountBalance()
      ]).catch($onError).finally(() => status.setIdle());
    });
    useIntervalFn(appStore.pollMarkets, 1e3 * 10);
    return (_ctx, _cache) => {
      const _component_PartialsAccount = _resolveComponent("PartialsAccount");
      const _component_AppHocLoading = _resolveComponent("AppHocLoading");
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        class: "h-full",
        status: _unref(status),
        "show-loading": !_unref(walletStore).isUserWalletConnected
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_PartialsAccount, { balances: _unref(balances) }, null, 8, ["balances"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["status", "show-loading"]);
    };
  }
});
