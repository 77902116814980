"use strict";
import { defineStore } from "pinia";
import { useDerivativeStore } from "../derivative";
import { indexerDerivativesApi } from "@/app/Services";
import {
  addMarginToPosition,
  closePosition,
  closeAllPosition,
  closePositionAndReduceOnlyOrders
} from "@/store/position/message";
import {
  streamSubaccountPositions,
  cancelSubaccountPositionsStream
} from "@/store/position/stream";
const initialStateFactory = () => ({
  orderbooks: {},
  subaccountPositions: [],
  subaccountPositionsCount: 0
});
export const usePositionStore = defineStore("position", {
  state: () => initialStateFactory(),
  actions: {
    addMarginToPosition,
    closePosition,
    closeAllPosition,
    closePositionAndReduceOnlyOrders,
    cancelSubaccountPositionsStream,
    streamSubaccountPositions,
    async fetchSubaccountPositions(activityFetchOptions) {
      const derivativeStore = useDerivativeStore();
      const positionStore = usePositionStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      const filters = activityFetchOptions == null ? void 0 : activityFetchOptions.filters;
      const { positions, pagination } = await indexerDerivativesApi.fetchPositions({
        subaccountId,
        marketIds: (filters == null ? void 0 : filters.marketIds) || derivativeStore.activeMarketIds,
        direction: filters == null ? void 0 : filters.direction
      });
      positionStore.$patch({
        subaccountPositions: positions,
        subaccountPositionsCount: pagination.total
      });
    },
    // Fetching multiple market orderbooks for unrealized PnL calculation within
    async fetchMarketsOrderbook() {
      const positionStore = usePositionStore();
      const { markets } = useDerivativeStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      if (markets.length === 0) {
        return;
      }
      const marketsOrderbook = await indexerDerivativesApi.fetchOrderbooksV2(
        markets.map((market) => market.marketId)
      );
      const marketsOrderbookMap = marketsOrderbook.reduce(
        (marketOrderbooks, { orderbook }, index) => {
          return {
            ...marketOrderbooks,
            [markets[index].marketId]: orderbook
          };
        },
        {}
      );
      positionStore.$patch({
        orderbooks: marketsOrderbookMap
      });
    },
    // Fetching multiple market orderbooks for unrealized PnL calculation within a market page
    async fetchOpenPositionsMarketsOrderbook() {
      const positionStore = usePositionStore();
      const { subaccountId } = useBankStore();
      const { isUserWalletConnected } = useWalletStore();
      const { subaccountPositions } = positionStore;
      if (!isUserWalletConnected || !subaccountId) {
        return;
      }
      if (subaccountPositions.length === 0) {
        return;
      }
      const marketsOrderbook = await indexerDerivativesApi.fetchOrderbooksV2(
        subaccountPositions.map((position) => position.marketId)
      );
      const marketsOrderbookMap = marketsOrderbook.reduce(
        (marketOrderbooks, { orderbook }, index) => {
          return {
            ...marketOrderbooks,
            [subaccountPositions[index].marketId]: orderbook
          };
        },
        {}
      );
      positionStore.$patch({
        orderbooks: marketsOrderbookMap
      });
    }
  }
});
