"use strict";
import { defineStore } from "pinia";
import { fetchNinjaPassCodes } from "@/app/services/ninjapass";
const initialStateFactory = () => ({
  codes: []
});
export const useNinjaPassStore = defineStore("ninjaPass", {
  state: () => initialStateFactory(),
  actions: {
    async fetchCodes() {
      const ninjaPassStore = useNinjaPassStore();
      const { injectiveAddress } = useWalletStore();
      if (!injectiveAddress) {
        return;
      }
      const codes = await fetchNinjaPassCodes(injectiveAddress) || [];
      ninjaPassStore.$patch({
        codes
      });
    }
  }
});
