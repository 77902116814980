"use strict";
import { defineStore } from "pinia";
import { BigNumberInWei, INJ_DENOM } from "@injectivelabs/utils";
import { indexerAccountPortfolioApi } from "@/app/Services";
import { INJ_GAS_BUFFER } from "@/app/utils/constants";
import {
  streamBankBalance,
  streamSubaccountBalance,
  cancelBankBalanceStream,
  cancelSubaccountBalanceStream
} from "@/store/bank/stream";
import { deposit, transfer, withdraw } from "@/store/bank/message";
const initialStateFactory = () => ({
  bankBalances: [],
  subaccountId: "",
  subaccountBalancesMap: {}
});
export const useBankStore = defineStore("bank", {
  state: () => initialStateFactory(),
  getters: {
    balanceMap: (state) => {
      if (state.bankBalances.length === 0) {
        return {};
      }
      return state.bankBalances.reduce((list, balance) => {
        return { ...list, [balance.denom]: balance.amount };
      }, {});
    },
    defaultSubaccountBalances: (state) => {
      const walletStore = useWalletStore();
      if (!walletStore.defaultSubaccountId) {
        return [];
      }
      return state.subaccountBalancesMap[walletStore.defaultSubaccountId];
    },
    hasEnoughInjForGas: (state) => {
      var _a;
      const walletStore = useWalletStore();
      const injBalance = ((_a = state.bankBalances.find(({ denom }) => denom === INJ_DENOM)) == null ? void 0 : _a.amount) || "0";
      const hasEnoughInjForGas = new BigNumberInWei(injBalance).toBase().gte(INJ_GAS_BUFFER);
      return walletStore.isWalletExemptFromGasFee || hasEnoughInjForGas;
    }
  },
  actions: {
    deposit,
    transfer,
    withdraw,
    streamBankBalance,
    streamSubaccountBalance,
    async fetchAccountPortfolio() {
      const bankStore = useBankStore();
      const walletStore = useWalletStore();
      if (!walletStore.injectiveAddress) {
        return;
      }
      const accountPortfolio = await indexerAccountPortfolioApi.fetchAccountPortfolio(
        walletStore.injectiveAddress
      );
      const defaultAccountBalances = accountPortfolio == null ? void 0 : accountPortfolio.subaccountsList.reduce(
        (accountBalances, balance) => {
          var _a, _b;
          if (balance.subaccountId === walletStore.defaultSubaccountId) {
            return [
              ...accountBalances,
              {
                denom: balance.denom,
                totalBalance: ((_a = balance.deposit) == null ? void 0 : _a.totalBalance) || "0",
                availableBalance: ((_b = balance.deposit) == null ? void 0 : _b.availableBalance) || "0"
              }
            ];
          }
          return accountBalances;
        },
        []
      );
      bankStore.$patch({
        subaccountId: walletStore.defaultSubaccountId,
        bankBalances: (accountPortfolio == null ? void 0 : accountPortfolio.bankBalancesList) || [],
        subaccountBalancesMap: {
          [walletStore.defaultSubaccountId]: defaultAccountBalances
        }
      });
    },
    reset() {
      cancelBankBalanceStream();
      cancelSubaccountBalanceStream();
      useBankStore().$reset();
    }
  }
});
