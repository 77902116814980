"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "h-full w-full flex flex-wrap py-14" };
const _hoisted_2 = { class: "container xl:max-w-6xl" };
const _hoisted_3 = { class: "flex justify-between mb-10" };
const _hoisted_4 = { class: "flex flex-col" };
const _hoisted_5 = { class: "text-3xl font-bold mb-2" };
const _hoisted_6 = { class: "text-lg" };
const _hoisted_7 = /* @__PURE__ */ _createElementVNode("div", null, [
  /* @__PURE__ */ _createElementVNode("img", {
    class: "w-full",
    src: "/svg/leaderboard.svg",
    alt: "leaderboard"
  })
], -1);
const _hoisted_8 = { key: 0 };
const _hoisted_9 = { key: 1 };
const _hoisted_10 = { key: 2 };
const _hoisted_11 = { key: 3 };
const _hoisted_12 = { key: 4 };
const _hoisted_13 = { class: "text-xs text-gray-400 mr-8" };
const _hoisted_14 = { class: "h-10 flex items-center" };
const _hoisted_15 = { class: "text-xs text-gray-500" };
const _hoisted_16 = {
  key: 0,
  class: "text-xs text-blue-500"
};
const _hoisted_17 = { class: "text-xs text-white" };
import { format } from "date-fns";
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "leaderboard",
  setup(__props) {
    definePageMeta({
      middleware: ["leaderboard"]
    });
    const leaderboardStore = useLeaderboardStore();
    const { t } = useLang();
    const { $onError } = useNuxtApp();
    const FilterList = {
      Overall: "overall",
      Volume: "volume",
      ROI: "roi",
      PnL: "pnl",
      Competition: "competition"
    };
    const SelectList = {
      Day: "1d",
      Week: "7d"
    };
    const resolutionOptions = [
      {
        display: t("leaderboard.resolutionOptions.daily"),
        value: SelectList.Day
      },
      {
        display: t("leaderboard.resolutionOptions.weekly"),
        value: SelectList.Week
      }
    ];
    const activeTab = ref(FilterList.Overall);
    const resolution = ref(SelectList.Day);
    const status = reactive(new Status(StatusType.Loading));
    const formattedLastUpdatedAt = computed(() => {
      const timestamp = new Date(0);
      timestamp.setUTCSeconds(leaderboardStore.lastUpdatedAt);
      return format(timestamp, "yyyy-MM-dd HH:mm:ss");
    });
    onMounted(() => {
      Promise.all([leaderboardStore.init()]).catch($onError).finally(() => {
        status.setIdle();
      });
    });
    function fetchLeaderboard() {
      status.setLoading();
      return leaderboardStore.fetchLeaderboard(resolution.value).catch($onError).finally(() => {
        status.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_CommonTabMenuItem = _resolveComponent("CommonTabMenuItem");
      const _component_AppSelectButton = _resolveComponent("AppSelectButton");
      const _component_AppSelect = _resolveComponent("AppSelect");
      const _component_CommonTabMenu = _resolveComponent("CommonTabMenu");
      const _component_PartialsLeaderboardTable = _resolveComponent("PartialsLeaderboardTable");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("leaderboard.title")), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("leaderboard.description")), 1)
            ]),
            _hoisted_7
          ]),
          _createVNode(_component_CommonTabMenu, null, {
            actions: _withCtx(() => [
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("leaderboard.lastUpdatedAt", {
                timestamp: _unref(formattedLastUpdatedAt)
              })), 1),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_AppSelect, {
                  modelValue: _unref(resolution),
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event) => _isRef(resolution) ? resolution.value = $event : null),
                    fetchLeaderboard
                  ],
                  options: resolutionOptions
                }, {
                  prefix: _withCtx(() => [
                    _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("leaderboard.resolution")), 1)
                  ]),
                  default: _withCtx(({ selected }) => [
                    selected ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(selected.display), 1)) : _createCommentVNode("", true)
                  ]),
                  option: _withCtx(({ option }) => [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(option.display), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(FilterList), (filterType) => {
                return _openBlock(), _createBlock(_component_AppSelectButton, {
                  key: `leaderboard-tabs-${filterType}`,
                  modelValue: _unref(activeTab),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => _isRef(activeTab) ? activeTab.value = $event : null),
                  value: filterType
                }, {
                  default: _withCtx(({ active }) => [
                    _createVNode(_component_CommonTabMenuItem, { active }, {
                      default: _withCtx(() => [
                        filterType === FilterList.Overall ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("leaderboard.tabs.overall")), 1)) : filterType === FilterList.Volume ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("leaderboard.tabs.volume")), 1)) : filterType === FilterList.ROI ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("leaderboard.tabs.roi")), 1)) : filterType === FilterList.PnL ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("leaderboard.tabs.pnl")), 1)) : filterType === FilterList.Competition ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("leaderboard.tabs.summerTradingCompetition")), 1)) : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["active"])
                  ]),
                  _: 2
                }, 1032, ["modelValue", "value"]);
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_PartialsLeaderboardTable, { status: _unref(status) }, null, 8, ["status"])
        ])
      ]);
    };
  }
});
