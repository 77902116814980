export default {
  home: {
    title: 'Revolutionize your Crypto journey with the ultimate decentralized exchange.',
    subtitle:
      'Trade unlimited cross-chain crypto assets and perpetual markets with market leading rebates*',
    tradeNow: 'Trade Now',
    latestNews: 'Latest News',
    viewAllMarkets: 'View all markets',
    trending: 'Trending',
    newlyAdded: 'Newly added',
    totalTradingVolume: 'Total trading volume',
    totalTrades: 'Total trades',
    sevenDaysPrice: '7 days price',
    newToCrypto: 'New to Crypto',
    experienceTrader: 'An Experienced Trader',
    institutionalTrader: 'An Institutional Trader',
    builtOn: 'Proudly built on',
    startTradingNote: 'Join the Trading Revolution',
    whyTradeOnHelix: 'Why Choose Parallel?',
    decentralizedWithAdvancedTypeOrders:
      'Trade with ease on our premier decentralized orderbook exchange',
    enjoySpotTrading:
      'Enjoy trading spot and perpetual markets on the premier front-running resistant decentralized orderbook exchange using stop-loss and take-profit orders.',
    lowFees: 'Maximum benefits and rewards with minimal fees',
    zeroGasFees:
      'Zero gas fees, low taker fees and highly competitive maker fee rebates for the best trading experience amongst all exchanges.',
    crossChainAssets: 'Cross-chain assets and novel markets',
    seamlesslyTransferAssets:
      'Trade across different chains and discover unique markets with Parallel Exchange. Transfer assets from Ethereum and Cosmos networks to access popular markets not available elsewhere',
    getStarted: 'Get Started in 3 Simple Steps',
    howToBridge: 'Transfer assets to begin',
    injective: 'INJECTIVE',
    howToPurchaseTokens: 'Trade spot markets',
    howToPlaceStopOrders: 'Trade perpetuals with advanced orders',
    startTradingNow: 'Start trading now',
    fast: 'Fast',
    interoperable: 'Interoperable',
    gasFree: 'Gas Free',
    secure: 'Secure',
    market: 'Market',
    lastPrice: 'Last price',
    change24h: 'Change (24H)'
  },

  newsletter: {
    title: 'Subscribe to Parallel newsletter',
    emailAddress: 'Email address',
    subscribe: 'Subscribe',
    disclaimer: 'Disclaimer',
    privacyPolicy: 'Privacy Policy',
    termsAndCondition: 'Terms and Conditions',
    subscribeToast: "You've successfully subscribed to our newsletter!",
    disclaimerMessage:
      'By subscribing, you agree to the {termsAndCondition}, have read the {privacyPolicy} and acknowledge that you have read and understand the {disclaimer}.'
  },

  footer: {
    resources: 'Resources',
    analytics: 'Analytics',
    termsAndConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    support: 'Support',
    faq: 'FAQ',
    submitRequest: 'Submit A Request',
    feeDiscounts: 'Fee Discounts',
    apiDocumentation: 'API Documentation',
    community: 'Community',
    helixProvides:'*Note: Parallel is able to provide maker rebates across a number of markets as approved via the Injective DAO.'

  }
}
